<template>
  <b-row v-if="incidence">
    <b-col cols="12">
      <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
        <div>
          <h2 class="font-weight-bolder m-0 hGoBack">
            <span @click="$router.go(-1)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
            </span> Detalle de la incidencia
          </h2>
        </div>
      </div>
      <!-- info -->
      <b-card>
        <div class="d-flex align-items-center">
          <h3 class="font-weight-bolder hTitle">
            {{ incidence.name }}
          </h3>
          <div class="ml-2">
            <b-badge class="px-2" :variant="statusIncidenceVariants[incidence.apto]" style="top: -3px">
              {{ statusIncidenceTexts[incidence.apto] }}
            </b-badge>
          </div>
          <div class="ml-auto">
            <span v-if="currentUser.roles[0].name === 'super_admin' || currentUser.id == incidence.user.id">
              <b-link v-if="incidence.apto === 0" :to="{ name: 'editIncidence', params: { id: incidence.id } }"
                class="btn-primary mr-1">
                <span><feather-icon icon="Edit2Icon" /> Editar</span>
              </b-link>
            </span>
            <b-link
              v-if="(currentUser.roles[0].name === 'super_admin' || currentUser.id == incidence.user.id) && incidence.apto === 0"
              class="btn btn-primary" @click="closeIncidence(incidence.id, incidence.name)">
              Cerrar Incidencia
            </b-link>
          </div>
        </div>
        <hr>
        <b-row class="body-info">
          <b-col cols="12">
            <b-row>
              <b-col md="6" sm="12">
                <p class="m-0"><strong>Nombre:</strong></p>
                <small>{{ incidence.name }}</small>
              </b-col>
              <b-col md="6" sm="12">
                <p class="m-0"><strong>Fecha:</strong></p>
                <small>{{ dateColumn(incidence.date) }}</small>
              </b-col>
              <b-col md="6" sm="12" v-if="incidence.user">
                <p class="m-0"><strong>Técnico:</strong></p>
                <b-link :to="{ name: 'viewUser', params: { id: incidence.user.id } }" class="link">
                  <small>{{ incidence.user.name }} {{ incidence.user.surname }}</small>
                </b-link>
              </b-col>
              <b-col md="6" sm="12" v-if="incidence.project">
                <p class="m-0"><strong>Obra:</strong></p>
                <b-link :to="{ name: 'viewProject', params: { id: incidence.project.id } }" class="link">
                  <small>{{ incidence.project.name }}</small>
                </b-link>
              </b-col>
              <b-col md="6" sm="12" v-if="incidence.maintenance">
                <p class="m-0"><strong>Mantenimiento:</strong></p>
                <small>{{ incidence.maintenance.ref }} - {{
                  incidence.maintenance.name
                }}</small>
              </b-col>
              <b-col md="6" sm="12" v-if="incidence.asset">
                <p class="m-0"><strong>Equipo:</strong></p>
                <b-link :to="{ name: 'viewAsset', params: { id: incidence.asset.id } }" class="link">
                  <small>{{ incidence.asset.product_name }}</small>
                </b-link>
              </b-col>
              <b-col cols="6">
                <template v-if="(incidence.description !== null)">
                  <p class="m-0"><strong>Descripción:</strong></p>
                  <div v-html="incidence.description"></div>
                </template>
              </b-col>
              <b-col cols="6" v-if="(incidence.description_resolution !== null)">
                <p class="m-0"><strong>Resolución Incidencia:</strong></p>
                <span>{{ incidence.description_resolution }}</span>
              </b-col>
              <b-col md="6" sm="12" v-if="incidence.documents && incidence.documents.length > 0">
                <p class="m-0"><strong>Imágenes:</strong></p>
                <div v-if="incidence.documents.length > 0" v-for="item in incidence.documents">
                  <b-link target="_blank" :href="item.path">
                    <small>
                      {{ item.name }}
                    </small>
                  </b-link>
                </div>
              </b-col>
              <b-col cols="12" v-if="(incidence.causes_incidence !== null)">
                <p class="m-0"><strong>Causa Incidencia:</strong></p>
                <div v-html="incidence.causes_incidence"></div>
              </b-col>
            </b-row>
            <!-- <b-row>
            </b-row> -->
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <CloseIncidenceModal />
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BLink, BButton, BFormRadio, BFormGroup, BForm, BFormInput, BContainer, BCardTitle, BBadge, BTable, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { config } from '@/shared/app.config'
import { TimestampToFlatPickr } from '@/libs/helpers'

export default {
  components: {
    BButton,
    vSelect,
    BLink,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BContainer,
    BFormRadio,
    BFormGroup,
    BForm,
    BFormInput,
    BBadge,
    CloseIncidenceModal: () => import('@/components/incidences/modals/CloseIncidenceModal.vue'),
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusIncidenceVariants: config.statusIncidenceVariants,
      statusIncidenceTexts: config.statusIncidenceTexts,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      sortBy: '',
      sortDesc: '',


    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/getUser',
      incidence: 'incidences/getIncidence',
      totalItems: 'incidences/getTotalItems',
    }),
  },
  methods: {
    ...mapActions({
      getIncidence: 'incidences/getIncidence',
    }),
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    closeIncidence() {
      this.$root.$emit('bv::show::modal', 'modalCloseIncidence')
    }

  },
  async created() {

    await this.getIncidence(this.$route.params.id)

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
